/* import React, { useState, useEffect } from 'react';
import axios from 'axios';

axios.defaults.baseURL = 'https://test-set.rkgroup.workers.dev';
axios.defaults.withCredentials = true; // Ensures cookies are sent with every request

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.cookie && console.log("Cookies available: ", document.cookie); // Logs cookies available to JS (HttpOnly will not show up)
  }, []);

  const setCookie = async () => {
    try {
      const response = await axios.get('/set-cookie');
      console.log('Set-Cookie Response:', response);
      setMessage('Cookie set successfully. Please click on "Check Login Status" to verify.');
    } catch (error) {
      console.error('Error setting cookie:', error);
      setMessage('Failed to set cookie. Check console for more info.');
    }
  };

  const checkLoginStatus = async () => {
    try {
      const response = await axios.get('/check-login');
      console.log('Check-Login Response:', response);
      if (response.data === 'true') {
        setIsLoggedIn(true);
        setMessage('Logged in successfully');
      } else {
        setIsLoggedIn(false);
        setMessage('Not logged in');
      }
    } catch (error) {
      console.error('Login check failed:', error);
      setMessage('Failed to check login status. Check console for more info.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>{message}</p>
        <button onClick={setCookie}>Set Cookie</button>
        <button onClick={checkLoginStatus}>Check Login Status</button>
        {isLoggedIn ? <h2>You are logged in</h2> : <h2>You are not logged in</h2>}
      </header>
    </div>
  );
}

export default App;
 */




import axios from 'axios';
import React, { useState } from 'react';

axios.defaults.baseURL = 'https://test-set.rkgroup.workers.dev';
axios.defaults.withCredentials = true; // Ensures cookies are sent with every request

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [message, setMessage] = useState('');

  const setCookie = async () => {
    try {
      const response = await axios.get('/set-cookie', {withCredentials: true});
      setMessage(response.data);
      console.log('Set-Cookie Response:', response.data);
    } catch (error) {
      console.error('Error setting cookie:', error);
      setMessage('Failed to set cookie. Check console for more info.');
    }
  };

  const checkLoginStatus = async () => {
    try {
      const response = await axios.get('/check-login', {withCredentials: true});
      console.log('Check-Login Response:', response.data);
      if (response.data === true) {
        setIsLoggedIn(true);
        setMessage('Logged in successfully');
      } else {
        setIsLoggedIn(false);
        setMessage('Not logged in');
      }
    } catch (error) {
      console.error('Login check failed:', error);
      setMessage('Failed to check login status. Check console for more info.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>{message}</p>
        <button onClick={setCookie}>Set Cookie</button>
        <button onClick={checkLoginStatus}>Check Login Status</button>
        <h2>{isLoggedIn ? "You are logged in" : "You are not logged in"}</h2>
      </header>
    </div>
  );
}

export default App;
